// QuestsScreen.js
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faClose,
    faShareAlt,
    faUserPlus,
    faPuzzlePiece,
    faGamepad,
    faComment,
    faTrophy,
    faUsers,
    faChartLine,
    faCubes,
    faCode,
    faMobileAlt,
    faCheck,
    faDownload,
    faVideo,
    faShoppingCart,
    faStar,
    faHandshake, faQuestion
} from '@fortawesome/free-solid-svg-icons';
import {
    faYoutube,
    faTelegram,
    faDiscord,
    faTwitter,
    faInstagram,
    faLinkedin,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {localGetItem} from "../utils/cloudStorage";
import {useNavigate} from "react-router-dom";
import useSWR, {mutate} from 'swr';
import {
    Engine,
    Scene,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    ParticleSystem,
    Texture,
    Color4
} from 'babylonjs';
import ReactGA from "react-ga4";

library.add(
    faClose, faShareAlt, faUserPlus, faPuzzlePiece, faGamepad, faCheck, faComment,
    faTrophy, faUsers, faChartLine, faCubes, faCode, faMobileAlt, faYoutube,
    faTelegram, faDiscord, faTwitter, faInstagram, faLinkedin, faTiktok,
    faDownload, faVideo, faShoppingCart, faStar, faHandshake
);

const iconMap = {
    'faTelegram': faTelegram,
    'faComment': faComment,
    'faUsers': faUsers,
    'faCubes': faCubes,
    'faShareAlt': faShareAlt,
    'faInstagram': faInstagram,
    'faDiscord': faDiscord,
    'faTwitter': faTwitter,
    'faLinkedin': faLinkedin,
    'faYoutube': faYoutube,
    'faTiktok': faTiktok,
    'faShoppingCart': faShoppingCart,
    'faDownload': faDownload,
    'faCode': faCode,
    'faMobileAlt': faMobileAlt,
    'faTrophy': faTrophy,
    'faVideo': faVideo,
    'faGamepad': faGamepad,
    'faPuzzlePiece': faPuzzlePiece,
    'faUserPlus': faUserPlus,
    'faStar': faStar,
    'faHandshake': faHandshake,
    'faChartLine': faChartLine
};

const QuestSkeleton = () => (
    <div className="quest-item skeleton">
        <div className="quest-info">
            <div className="quest-icon skeleton-circle"></div>
            <span className="quest-name"></span>
        </div>
        <span className="quest-reward skeleton-text"></span>
    </div>
);

const formatScore = (score) => {
    if (score >= 1000000) {
        return `${(score / 1000000).toFixed(0)}M`;
    } else if (score >= 10000) {
        return `${(score / 1000).toFixed(0)}K`;
    }
    return score.toLocaleString();
}

const fetcher = (url, token) =>
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    }).then((res) => {
        if (!res.ok) {
            throw new Error('Failed to fetch');
        }
        return res.json();
    });

const QuestsScreen = ({setBalance, balance}) => {
    const [selectedQuest, setSelectedQuest] = useState(null);
    const [token, setToken] = useState(localGetItem("Token"));
    const [activeTab, setActiveTab] = useState('daily');
    const navigate = useNavigate();
    const referralLink = `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/app?startapp=${window.Telegram.WebApp.initDataUnsafe.user?.id}`;
    const [preloadedImages, setPreloadedImages] = useState({});
    const [inputValue, setInputValue] = useState('');
    const popupRef = useRef(null);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const {data: missions, error} = useSWR(
        token ? [`${process.env.REACT_APP_SERVICE_URL}/api/missions`, token] : null,
        ([url, token]) => fetcher(url, token)
    );

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_quests_screen",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        })
    }, [])

    useEffect(() => {
        const handleResize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        const handleFocus = () => {
            // Сохраняем текущую позицию скролла
            window.scrollY = window.pageYOffset;
        };

        const handleBlur = () => {
            // Устанавливаем таймаут, чтобы дать время клавиатуре полностью скрыться
            setTimeout(() => {
                // Восстанавливаем позицию скролла
                window.scrollTo(0, window.scrollY);
                // Выполняем дополнительный скролл до конца попапа
                if (popupRef.current) {
                    popupRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }, 100);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        // Добавляем слушатели событий для input
        const input = document.querySelector('.quest-popup-input');
        if (input) {
            input.addEventListener('focus', handleFocus);
            input.addEventListener('blur', handleBlur);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
            if (input) {
                input.removeEventListener('focus', handleFocus);
                input.removeEventListener('blur', handleBlur);
            }
        };
    }, [selectedQuest]);

    const preloadImage = useCallback((src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
        });
    }, []);

    useEffect(() => {
        if (missions) {
            const imageUrls = missions
                .map(mission => mission.image_url)
                .filter(url => url && !preloadedImages[url]);

            Promise.all(imageUrls.map(url => preloadImage(url)))
                .then(loadedUrls => {
                    const newPreloadedImages = loadedUrls.reduce((acc, url) => {
                        acc[url] = true;
                        return acc;
                    }, {});
                    setPreloadedImages(prev => ({...prev, ...newPreloadedImages}));
                })
                .catch(error => console.error('Error preloading images:', error));
        }
    }, [missions, preloadImage, preloadedImages]);

    useEffect(() => {
        const canvas = document.getElementById('renderCanvas');
        const engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
        const scene = createScene(engine, canvas);

        engine.runRenderLoop(() => {
            scene.render();
        });

        window.addEventListener('resize', () => {
            engine.resize();
        });

        return () => {
            engine.dispose();
        };
    }, []);

    const createScene = (engine, canvas) => {
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0.02, 0.02, 0.05, 1);

        const camera = new ArcRotateCamera('camera', -Math.PI / 2, Math.PI / 2.5, 10, new Vector3(0, 0, 0), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 5;
        camera.upperRadiusLimit = 15;
        camera.wheelPrecision = 50;
        camera.pinchPrecision = 50;

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        const particleSystem = new ParticleSystem('particles', 10000, scene);
        particleSystem.particleTexture = new Texture('https://www.babylonjs-playground.com/textures/flare.png', scene);
        particleSystem.emitter = new Vector3(0, 0, 0);
        particleSystem.minEmitBox = new Vector3(-10, -10, -10);
        particleSystem.maxEmitBox = new Vector3(10, 10, 10);
        particleSystem.color1 = new Color4(0.7, 0.4, 1, 1);
        particleSystem.color2 = new Color4(0.5, 0.3, 0.8, 1);
        particleSystem.colorDead = new Color4(0.2, 0.2, 0.5, 0);
        particleSystem.minSize = 0.05;
        particleSystem.maxSize = 0.3;
        particleSystem.minLifeTime = 2;
        particleSystem.maxLifeTime = 8;
        particleSystem.emitRate = 1000;
        particleSystem.blendMode = ParticleSystem.BLENDMODE_ADD;
        particleSystem.gravity = new Vector3(0, -0.05, 0);
        particleSystem.direction1 = new Vector3(-1, -1, -1);
        particleSystem.direction2 = new Vector3(1, 1, 1);
        particleSystem.minAngularSpeed = 0;
        particleSystem.maxAngularSpeed = Math.PI;
        particleSystem.minEmitPower = 0.5;
        particleSystem.maxEmitPower = 2;
        particleSystem.updateSpeed = 0.01;
        particleSystem.start();

        let time = 0;
        engine.runRenderLoop(() => {
            time += 0.016;

            const r = 0.54 + Math.sin(time * 1.1) * 0.1;
            const g = 0.17 + Math.sin(time * 1.3) * 0.05;
            const b = 0.89 + Math.sin(time * 1.7) * 0.1;

            particleSystem.color1 = new Color4(r + 0.2, g + 0.2, b + 0.1, 1);
            particleSystem.color2 = new Color4(r - 0.1, g + 0.1, b - 0.1, 1);

            scene.render();
        });

        return scene;
    };

    const handleMissionComplete = async (mission) => {
        try {
            console.log("Mission ID:", mission.id);

            let missionData = {missionId: mission.id};

            if (mission.description === "Become a WOWCube® Influencer") {
                missionData.email = inputValue;
            } else if (mission.description === "Buy CUBE and get AirDrop") {
                missionData.orderNumber = inputValue;
            }

            const response = await fetch(
                `${process.env.REACT_APP_SERVICE_URL}/api/mission-complete`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify(missionData)
                }
            );
            const responseData = await response.json();
            console.log("Response Data:", responseData);

            if (!response.ok) {
                console.error('Failed to complete mission:', response.status, responseData);
                throw new Error('Failed to complete mission');
            }

            mutate([`${process.env.REACT_APP_SERVICE_URL}/api/missions`, token]);

            setBalance((prevBalance) => prevBalance + mission.tokens);

        } catch (error) {
            console.error('Error completing mission:', error);
            alert('Please check the mission status later.');
        }
    };

    const handleQuestClick = (mission) => {
        setSelectedQuest(mission);
    };

    const handleClosePopup = () => {
        setSelectedQuest(null);
        setInputValue('');
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    };

    const handleCheckStatus = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
        if (selectedQuest.description === "Become a WOWCube® Influencer" ||
            selectedQuest.description === "Buy CUBE and get AirDrop") {
            if (!inputValue.trim()) {
                alert("Please enter the required information before checking status.");
                return;
            }
            handleMissionComplete(selectedQuest);
            handleClosePopup();
        } else {
            alert('Quest status: In Progress');
        }
    };

    const handleStartQuest = () => {
        handleMissionComplete(selectedQuest);

        if (selectedQuest && selectedQuest.url) {
            if (selectedQuest.url.startsWith('https://t.me')) {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                window.Telegram.WebApp.openTelegramLink(selectedQuest.url);
            } else if (selectedQuest.url.startsWith('http://') || selectedQuest.url.startsWith('https://')) {
                if (window.Telegram?.WebApp?.HapticFeedback) {
                    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                    window.Telegram.WebApp.openLink(selectedQuest.url);
                }
            } else {
                navigate(selectedQuest.url);
            }
        } else {
            console.error('Selected quest or URL is missing');
        }
    };


    const renderMissions = (missions) => {
        if (!missions) {
            // Отображаем скелетоны во время загрузки
            return Array(7).fill().map((_, index) => <QuestSkeleton key={index}/>);
        }

        // Sort missions: incomplete first, then completed
        const sortedMissions = [...missions].sort((a, b) => {
            // If one is completed and the other is not, put the completed one last
            if (a.isCompleted !== b.isCompleted) {
                return a.isCompleted ? 1 : -1;
            }
            // If both are incomplete or both are complete, sort by order
            return a.order - b.order;
        });

        return sortedMissions.map((mission, index) => {
            const isCompleted = mission.isCompleted;
            const icon = iconMap[mission.icon] || faQuestion; // Default to question mark if icon not found
            return (
                <div
                    className={`quest-item ${isCompleted ? 'completed' : ''}`}
                    key={index}
                    onClick={() => {
                        if (!isCompleted) {
                            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                            handleQuestClick(mission);
                        }
                    }}
                    style={{cursor: isCompleted ? 'default' : 'pointer'}}
                >
                    <div className="quest-info">
                        <FontAwesomeIcon icon={icon} className={`quest-icon ${isCompleted ? 'completed' : ''}`}/>
                        <span className={`quest-name ${isCompleted ? 'completed' : ''}`}>{mission.description}</span>
                    </div>
                    {isCompleted ? (
                        <FontAwesomeIcon icon={faCheck} className="quest-completed-icon"/>
                    ) : (
                        <span className="quest-reward">{formatScore(mission.tokens)} $WOW</span>
                    )}
                </div>
            );
        });
    };

    return (
        <>
            <canvas id='renderCanvas' touch-action="none"></canvas>
            <div className="screen active">
                <div className="tab-container">
                    <button className={`tab ${activeTab === 'daily' ? 'active' : ''}`}
                            onClick={() => setActiveTab('daily')}>Tasks
                    </button>
                    <button className={`tab ${activeTab === 'social' ? 'active' : ''}`}
                            onClick={() => setActiveTab('social')}>Socials
                    </button>
                    <button className={`tab ${activeTab === 'special' ? 'active' : ''}`}
                            onClick={() => setActiveTab('special')}>Special
                    </button>
                </div>
                <div className="quests">
                    <div className="tab-content">
                        <div className='quests-title'>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Quests
                        </div>
                        <div className="quests-list">
                            {renderMissions(missions ? missions.filter(mission => mission.type === activeTab) : null)}
                        </div>
                    </div>
                </div>

                <div className="progress-barRD" id="progress-barRD"></div>
                <div className="mission-listRD" id="mission-listRD"></div>
            </div>

            {selectedQuest && !selectedQuest.isCompleted && (
                <div className="quest-popup active" ref={popupRef}>
                    <button className="quest-popup-close" onClick={handleClosePopup}>
                        <FontAwesomeIcon icon={faClose}/>
                    </button>
                    <div
                        className="quest-popup-image"
                        style={{
                            backgroundImage: `url('${
                                (selectedQuest.image_url && preloadedImages[selectedQuest.image_url])
                                    ? selectedQuest.image_url
                                    : `${process.env.REACT_APP_SERVICE_URL}/api/images/logo.webp`
                            }')`
                        }}
                    ></div>
                    <h2 className="quest-popup-title">{selectedQuest.description}</h2>
                    <div className="quest-popup-reward">+{selectedQuest.tokens} $WOW</div>
                    {selectedQuest.description !== "Become a WOWCube® Influencer" &&
                        selectedQuest.description !== "Buy CUBE and get AirDrop" && (
                            <p className="quest-popup-description">
                                Complete this mission to earn {formatScore(selectedQuest.tokens)} $WOW!
                            </p>
                        )}
                    <button
                        className="quest-popup-cta"
                        onClick={handleStartQuest}
                    >
                        Start Mission
                    </button>
                    {(selectedQuest.description === "Become a WOWCube® Influencer" ||
                        selectedQuest.description === "Buy CUBE and get AirDrop") && (
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder={selectedQuest.description === "Become a WOWCube® Influencer" ? "Enter your email" : "Enter order number"}
                            className="quest-popup-input"
                            enterKeyHint="enter"
                        />
                    )}
                    <button className="quest-popup-status" onClick={handleCheckStatus}>
                        Check Status
                    </button>
                </div>
            )}
        </>
    );
}

export default QuestsScreen;
