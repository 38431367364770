import styles from './../styles/Loading.module.css';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {localGetItem, localSetItem} from "../utils/cloudStorage";
import ReactGA from "react-ga4";
import axios from 'axios';
import PromoHandlerUtility from "../utils/PromoHandlerUtility";

function Loading({setExternalBalance, setExternalLevel}) {
    const navigate = useNavigate();
    const [token, setToken] = useState(localGetItem("Token"));
    const [progress, setProgress] = useState(0);
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [loadingText, setLoadingText] = useState("Initializing...");

    const mainPageRoute = '/play';
    const onboardingRoute = '/onboarding';

    const updateProgress = (step, text) => {
        setProgress(prevProgress => {
            const newProgress = prevProgress + step;
            return newProgress > 100 ? 100 : newProgress;
        });
        setLoadingText(text);
    };

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "main_loading",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        });

        async function fetchAuthToken(userData) {
            try {
                updateProgress(10, "Authenticating...");
                const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/app-auth`, {
                    method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                        user_id: userData?.id ? userData?.id : 793241784,
                        username: userData?.username ? userData?.username : "dangost",
                    })
                });
                const data = await response.json();
                setToken(data.token);
                localSetItem("Token", data.token, 3600 * 24);
                updateProgress(10, "Authentication complete");
            } catch (error) {
                console.error('Error fetching auth token:', error);
                updateProgress(10, "Authentication failed");
            }
        }

        const authtoken = localGetItem("Token");
        if (!authtoken) {
            fetchAuthToken(window.Telegram.WebApp.initDataUnsafe.user);
        } else {
            setToken(authtoken);
            updateProgress(20, "Authentication complete");
        }

        // Предзагрузка компонента Onboarding
        updateProgress(20, "Preparing resources...");
        const onboarded = localGetItem("onboarded", false);
        if (!onboarded) {
            import('./Onboarding').then(() => {
                updateProgress(10, "Resources loaded");
            });
        }

    }, []);

    function navigateToNextStep() {
        window.Telegram.WebApp.HapticFeedback.notificationOccurred('success');

        // Получаем параметры из URL
        const urlParams = new URLSearchParams(window.location.search);
        const startAppParam = urlParams.get('tgWebAppStartParam');

        // Проверяем наличие специальной команды в параметрах
        const navigationData = handleStartParam(startAppParam);
        if (navigationData) {
            navigate(navigationData.route, {
                state: navigationData.state
            });
            return;
        }

        // Стандартная логика навигации
        const onboarded = localGetItem("onboarded", false);
        if (!onboarded) {
            navigate(onboardingRoute);
        } else {
            navigate(mainPageRoute);
        }
    }

    useEffect(() => {
        if (!token) {
            return;
        }
        window.scrollTo(0, 0);
        window.Telegram.WebApp.expand();

        const urlParams = new URLSearchParams(window.location.search);
        let startAppParam = urlParams.get('tgWebAppStartParam');
        if (!startAppParam) {
            startAppParam = null;
        }

        if (window.Telegram.WebApp.initDataUnsafe) {
            const initData = window.Telegram.WebApp.initDataUnsafe;
            const userData = initData.user;

            const is_premium = initData.is_premium !== undefined ? initData.is_premium : false;
            const photo_url = initData.photo_url !== undefined ? initData.photo_url : (userData && userData.photo_url) ? userData.photo_url : null;

            const additionalUserData = {
                language_code: initData.language_code,
                is_premium: is_premium,
                allows_write_to_pm: initData.allows_write_to_pm,
                photo_url: photo_url
            };

            if (userData) {
                if (!token) {
                    navigateToNextStep();
                    return;
                }
                registerUserAndLoadProfile({...userData, ...additionalUserData}, startAppParam);
            }
        }
    }, [token]);

    function handleStartParam(startAppParam) {
        if (!startAppParam) return null;

        // Разбиваем строку по underscore
        const parts = startAppParam.split('_');

        // Проверяем, начинается ли с open
        if (parts[0] !== 'open' || parts.length < 2) return null;

        // Получаем route (второй элемент после open)
        const route = parts[1];

        // Собираем state object из оставшихся параметров
        const state = {};

        // Обрабатываем оставшиеся части как пары ключ-значение
        for (let i = 2; i < parts.length; i += 2) {
            if (i + 1 < parts.length) {
                state[parts[i]] = parts[i + 1];
            }
        }

        // Добавляем userId в state
        state.userId = window.Telegram.WebApp.initDataUnsafe.user?.id;

        return {
            route: `/${route}`,
            state
        };
    }


    const registerUserAndLoadProfile = async (userData, refererId) => {
        const initData = window.Telegram.WebApp.initDataUnsafe;
        const payload = window.Telegram.WebApp.initDataUnsafe.user;
        let fullname = userData.first_name;
        if (userData.last_name) {
            fullname = fullname + ' ' + userData.last_name;
        }

        let country = "undefined";

        try {
            const response = await axios.get('https://ipapi.co/json/');
            const {latitude, longitude, city, country_name} = response.data;
            country = country_name;
        } catch (error) {
            console.error(`Error: ${error.message}`);
        }

        let retryCount = 0;
        const maxRetries = 3;

        while (retryCount < maxRetries) {
            try {
                updateProgress(10, "Registering user...");
                const createUserResponse = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/create-user`, {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', 'Authorization': token
                    }, body: JSON.stringify({
                        user_id: userData?.id,
                        fullname: fullname,
                        username: userData.username,
                        referer_id: refererId,
                        language_code: payload.language_code,
                        is_premium: payload.is_premium,
                        allows_write_to_pm: payload.allows_write_to_pm,
                        photo_url: payload.photo_url,
                        country: country
                    })
                });

                if (createUserResponse.status !== 409 && !createUserResponse.ok) {
                    throw new Error('Failed to create user');
                }

                const isExistingUser = createUserResponse.status === 409;

                if (isExistingUser) {
                    setFirstLoad(false);
                }

                if (refererId) {
                    PromoHandlerUtility.handlePromoCode(refererId, userData?.id, isExistingUser);
                }

                updateProgress(10, "Updating user data...");
                if (payload.photo_url) {
                    await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/photo-url`, {
                        method: "PUT", headers: {
                            'Content-Type': 'application/json', 'Authorization': token
                        }, body: JSON.stringify({
                            photo_url: payload.photo_url
                        })
                    });
                }

                updateProgress(10, "Applying settings...");
                const vibrationEnabled = localGetItem('vibrationEnabled', true);
                if (!vibrationEnabled) {
                    window.Telegram.WebApp.HapticFeedback.impactOccurred = (_) => {
                    }
                }

                updateProgress(5, "Fetching balance...");
                const balanceResponse = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/balance/${userData?.id}`, {
                    method: 'GET', headers: {
                        'Authorization': token
                    },
                });

                if (!balanceResponse.ok) {
                    throw new Error(`Failed to fetch balance: ${balanceResponse.status}`);
                }

                const balanceData = await balanceResponse.json();
                setExternalBalance(balanceData.balance);

                updateProgress(5, "Fetching level data...");
                let levelData = localGetItem("Level");
                if (!levelData) {
                    const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/level`, {
                        method: 'GET', headers: {
                            'Content-Type': 'application/json', 'Authorization': token
                        }
                    });
                    levelData = await response.json();
                    localSetItem("Level", levelData, 3 * 3600);
                }

                setExternalLevel(levelData.lvl);
                updateProgress(100, "Ready!");

                setTimeout(() => {
                    navigateToNextStep();
                }, 500);

                // Если всё прошло успешно, выходим из цикла
                break;

            } catch (error) {
                console.error(`Error: ${error.message}`);
                updateProgress(10, `Error: ${error.message}. Retry ${retryCount + 1}/${maxRetries}`);
                retryCount++;

                if (retryCount === maxRetries) {
                    console.error('Max retries reached. Unable to complete the operation.');
                    // Здесь можно добавить логику обработки окончательной неудачи
                } else {
                    // Ждем перед следующей попыткой (например, 2 секунды)
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        }
    };

    return (<div className={styles.playScreen}>
        <div className={styles.version}>v1.3.2</div>
        <h1 className={styles.title}>WOWCUBE®</h1>
        <img className={styles.image} src={`${process.env.REACT_APP_SERVICE_URL}/api/images/logo.webp`}
             alt="WOWCUBE® operating system"/>
        <div className={styles.loadingBar}>
            <div className={styles.loadingProgress} style={{width: `${progress}%`}}></div>
        </div>
        <div className={styles.loadingText}>{loadingText}</div>
        <img className={styles.partnerLogo}
             src="https://www.intermedasia.com/wp-content/uploads/2024/01/Rubiks-Web-Rebrand.png"
             style={{display: "none"}}
             alt="Rubik's Logo"/>
    </div>);
}

export default Loading;