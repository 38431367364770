import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCoins,
    faLanguage,
    faBell,
    faWallet,
    faFileContract,
    faUserShield,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {localGetItem, localSetItem} from "../utils/cloudStorage";
import {TonConnectButton, useTonAddress} from "@tonconnect/ui-react";
import ReactGA from "react-ga4";

const Settings = ({balance}) => {
    const [vibrationEnabled, setVibrationEnabled] = useState(localGetItem('vibrationEnabled', true));
    const [user, setUser] = useState(window.Telegram.WebApp.initDataUnsafe.user);
    const [avatarLoading, setAvatarLoading] = useState(true);
    const [avatarError, setAvatarError] = useState(false);
    const tonAddress = useTonAddress(true);
    const [token, setToken] = useState(localGetItem("Token"));

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_settings",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        });
        setUser(window.Telegram.WebApp.initDataUnsafe.user);
        setVibrationEnabled(localGetItem('vibrationEnabled', true));

        const img = new Image();
        img.src = `${process.env.REACT_APP_SERVICE_URL}/api/avatar/${user?.id}`;
        img.onload = () => setAvatarLoading(false);
        img.onerror = () => {
            setAvatarLoading(false);
            setAvatarError(true);
        };
    }, []);

    useEffect(() => {
        if (tonAddress && token) {
            const userId = window.Telegram.WebApp.initDataUnsafe.user?.id;
            if (userId) {
                fetch(`${process.env.REACT_APP_SERVICE_URL}/api/connect-wallet`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({user_id: userId, ton_address: tonAddress})
                });
            }
        }
    }, [tonAddress, token]);

    useEffect(() => {
        localSetItem('vibrationEnabled', vibrationEnabled);
        if (!vibrationEnabled) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred = (_) => {
            };
        }
    }, [vibrationEnabled]);

    const handleVibrationToggle = () => {
        if (!vibrationEnabled) {
            alert('You need to restart the application to apply the settings');
        }
        setVibrationEnabled(!vibrationEnabled);
    };

    const renderAvatar = () => {
        if (avatarLoading) {
            return <div className="user-avatar skeleton-circle"></div>;
        }
        if (avatarError) {
            return <div className="user-avatar error"></div>;
        }
        return (<img
            src={`${process.env.REACT_APP_SERVICE_URL}/api/avatar/${user?.id}`}
            alt="User Avatar"
            className="user-avatar"
        />);
    };

    return (<div className="screen">
        <div className="store">
            <div className="user-profile">
                {renderAvatar()}
                <h2 className="store-title">{`${user?.first_name} ${user?.last_name}`}</h2>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <TonConnectButton/>
                </div>
            </div>

            <div className="balance-item">
                <FontAwesomeIcon icon={faCoins} className="balance-icon"/>
                <span className="balance-label">Balance</span>
                <span className="balance-amount">{balance}</span>
            </div>

            <div className="store-item">
                <div className="store-info">
                    <FontAwesomeIcon icon={faLanguage} className="store-icon"/>
                    <span className="store-name">Language</span>
                </div>
                <span className="store-price">English <span className="disabled-text">(More soon)</span></span>
            </div>

            <div className="store-item">
                <div className="store-info">
                    <FontAwesomeIcon icon={faBell} className="store-icon"/>
                    <span className="store-name">Vibration</span>
                </div>
                <label className="vibration-toggle">
                    <input
                        type="checkbox"
                        checked={vibrationEnabled}
                        onChange={handleVibrationToggle}
                    />
                    <span className="vibration-toggle-slider"></span>
                </label>
            </div>
            <div className="store-item"
                 onClick={() => window.Telegram.WebApp.openLink('https://wowcube.com/terms-of-service')}>
                <div className="store-info">
                    <FontAwesomeIcon icon={faFileContract} className="store-icon"/>
                    <span className="store-name">Terms of Service</span>
                </div>
                {/*<FontAwesomeIcon icon={faWallet} className="store-icon"/>*/}
            </div>

            <div className="store-item"
                 onClick={() => window.Telegram.WebApp.openLink('https://wowcube.com/privacy')}>
                <div className="store-info">
                    <FontAwesomeIcon icon={faUserShield} className="store-icon"/>
                    <span className="store-name">Privacy Notice</span>
                </div>
                {/*<FontAwesomeIcon icon={faWallet} className="store-icon"/>*/}
            </div>
            <div className="store-item" onClick={() => {
                localStorage.clear();
                alert("Cached cleared!")
            }}>
                <div className="store-info">
                    <FontAwesomeIcon icon={faTrash} className="store-icon"/>
                    <span className="store-name">Clear Cache</span>
                </div>
                {/*<FontAwesomeIcon icon={faWallet} className="store-icon"/>*/}
            </div>
        </div>
    </div>);
};

export default Settings;