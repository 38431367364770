import React, {useEffect, useRef, useState} from 'react';
import {ArcRotateCamera, Color4, Engine, HemisphericLight, ParticleSystem, Scene, Texture, Vector3} from 'babylonjs';
import useSWR from 'swr';
import ReactGA from "react-ga4";
import {calculateLevel} from '../data/levelsData';

const fetcher = (url) =>
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((res) => {
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }
        return res.json();
    });

const LeaderSkeleton = () => (
    <div className="leader-item skeleton">
        <div className="player-info">
            <span className="player-rank"></span>
            <div className="player-avatar skeleton-circle"></div>
            <span className="player-name"></span>
        </div>
        <span className="player-score skeleton-text"></span>
    </div>
);


const formatScore = (score) => {
    if (score >= 1000000) {
        return `${(score / 1000000).toFixed(2)}M`;
    } else if (score >= 10000) {
        return `${(score / 1000).toFixed(2)}K`;
    }
    return score.toLocaleString();
}

const LeadersScreen = () => {
    const [activeTab, setActiveTab] = useState('$WOW');
    const [activeSubTab, setActiveSubTab] = useState('Weekly');
    const [imageCache, setImageCache] = useState({});
    const isMounted = useRef(true);

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_leaders_page",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        })
    }, [])

    const {data: leaderboardData, error, isValidating} = useSWR(
        `${process.env.REACT_APP_SERVICE_URL}/api/leaderboard`,
        fetcher
    );

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (leaderboardData) {
            const allUserIds = [
                ...leaderboardData.tokens.all,
                ...leaderboardData.tokens.weekly,
                ...leaderboardData.tokens.monthly,
                ...leaderboardData.invites,
                ...leaderboardData.level
            ].map(user => user.user_id);

            allUserIds.forEach(userId => {
                if (!imageCache[userId]) {
                    const img = new Image();
                    img.src = `${process.env.REACT_APP_SERVICE_URL}/api/avatar/${userId}`;
                    img.onload = () => {
                        if (isMounted.current) {
                            setImageCache(prevCache => ({
                                ...prevCache,
                                [userId]: img.src
                            }));
                        }
                    };
                }
            });
        }
    }, [leaderboardData, imageCache]);

    useEffect(() => {
        const canvas = document.getElementById('renderCanvas');
        const engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
        const scene = createScene(engine, canvas);

        engine.runRenderLoop(() => {
            scene.render();
        });

        window.addEventListener('resize', () => {
            engine.resize();
        });

        return () => {
            engine.dispose();
        };
    }, []);

    const createScene = (engine, canvas) => {
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0.02, 0.02, 0.05, 1);

        const camera = new ArcRotateCamera('camera', -Math.PI / 2, Math.PI / 2.5, 10, new Vector3(0, 0, 0), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 5;
        camera.upperRadiusLimit = 15;
        camera.wheelPrecision = 50;
        camera.pinchPrecision = 50;

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        const particleSystem = new ParticleSystem('particles', 10000, scene);
        particleSystem.particleTexture = new Texture('https://www.babylonjs-playground.com/textures/flare.png', scene);
        particleSystem.emitter = new Vector3(0, 0, 0);
        particleSystem.minEmitBox = new Vector3(-10, -10, -10);
        particleSystem.maxEmitBox = new Vector3(10, 10, 10);
        particleSystem.color1 = new Color4(0.7, 0.4, 1, 1);
        particleSystem.color2 = new Color4(0.5, 0.3, 0.8, 1);
        particleSystem.colorDead = new Color4(0.2, 0.2, 0.5, 0);
        particleSystem.minSize = 0.05;
        particleSystem.maxSize = 0.3;
        particleSystem.minLifeTime = 2;
        particleSystem.maxLifeTime = 8;
        particleSystem.emitRate = 1000;
        particleSystem.blendMode = ParticleSystem.BLENDMODE_ADD;
        particleSystem.gravity = new Vector3(0, -0.05, 0);
        particleSystem.direction1 = new Vector3(-1, -1, -1);
        particleSystem.direction2 = new Vector3(1, 1, 1);
        particleSystem.minAngularSpeed = 0;
        particleSystem.maxAngularSpeed = Math.PI;
        particleSystem.minEmitPower = 0.5;
        particleSystem.maxEmitPower = 2;
        particleSystem.updateSpeed = 0.01;
        particleSystem.start();

        let time = 0;
        engine.runRenderLoop(() => {
            time += 0.016;

            const r = 0.54 + Math.sin(time * 1.1) * 0.1;
            const g = 0.17 + Math.sin(time * 1.3) * 0.05;
            const b = 0.89 + Math.sin(time * 1.7) * 0.1;

            particleSystem.color1 = new Color4(r + 0.2, g + 0.2, b + 0.1, 1);
            particleSystem.color2 = new Color4(r - 0.1, g + 0.1, b - 0.1, 1);

            scene.render();
        });

        return scene;
    };

    const renderTabContent = () => {
        if (error) return <div className="error-message">Error loading data. Please try again later.</div>;
        if (isValidating || !leaderboardData) return (
            <div className="leaders">
                <div className="leaders-title">Loading...</div>
                {Array(10).fill().map((_, index) => <LeaderSkeleton key={index}/>)}
            </div>
        );

        const renderLeaderItem = (leader, index) => (
            <div className="leader-item" key={leader.user_id || index}>
                <div className="player-info">
                    <span className="player-rank">#{index + 1}</span>
                    <img
                        className='player-avatar'
                        src={imageCache[leader.user_id] || `${process.env.REACT_APP_SERVICE_URL}/api/avatar/${leader.user_id}`}
                        alt='User avatar'
                    />
                    <span className="player-name">{leader.username || leader.fullname}</span>
                </div>
                <span className="player-score">
                {activeTab === 'Level'
                    ? `LVL ${calculateLevel(leader.value)}`
                    : activeTab === '$WOW'
                        ? `${formatScore(leader.value)}`
                        : leader.value
                }
            </span>
            </div>
        );

        switch (activeTab) {
            case '$WOW':
                let currentLeaderboard;
                switch (activeSubTab) {
                    case 'Monthly':
                        currentLeaderboard = leaderboardData.tokens.monthly;
                        break;
                    case 'Weekly':
                        currentLeaderboard = leaderboardData.tokens.weekly;
                        break;
                    case 'ALL':
                        currentLeaderboard = leaderboardData.tokens.all;
                        break;
                    default:
                        currentLeaderboard = [];
                }
                return (
                    <div className="leaders">
                        <div className="leaders-title">Top $WOW Holders</div>
                        {currentLeaderboard.length === 0
                            ? Array(10).fill().map((_, index) => <LeaderSkeleton key={index}/>)
                            : currentLeaderboard.map((leader, index) => renderLeaderItem(leader, index))
                        }
                    </div>
                );

            case 'Invites':
                const sortedInvites = leaderboardData.invites.sort((a, b) => b.value - a.value);
                return (
                    <div className="leaders">
                        <div className="leaders-title">Top Inviters</div>
                        {sortedInvites.length === 0
                            ? Array(10).fill().map((_, index) => <LeaderSkeleton key={index}/>)
                            : sortedInvites.map((inviter, index) => renderLeaderItem(inviter, index))
                        }
                    </div>
                );

            case 'Level':
                const sortedLevels = leaderboardData.tokens.all
                    .sort((a, b) => calculateLevel(b.value) - calculateLevel(a.value));
                return (
                    <div className="leaders">
                        <div className="leaders-title">Top Levels</div>
                        {sortedLevels.length === 0
                            ? Array(10).fill().map((_, index) => <LeaderSkeleton key={index}/>)
                            : sortedLevels.map((leveler, index) => renderLeaderItem(leveler, index))
                        }
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <canvas id='renderCanvas' touch-action="none"></canvas>
            <div className="screen active">
                <div className="tab-container">
                    <button className={`tab ${activeTab === 'Invites' ? 'active' : ''}`} onClick={() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                        setActiveTab('Invites');
                    }}>Invites
                    </button>
                    <button className={`tab ${activeTab === 'Level' ? 'active' : ''}`} onClick={() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                        setActiveTab('Level');
                    }}>Level
                    </button>
                    <button className={`tab ${activeTab === '$WOW' ? 'active' : ''}`} onClick={() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                        setActiveTab('$WOW');
                    }}>$WOW
                    </button>
                </div>
                {activeTab === '$WOW' && (
                    <SubTabs activeSubTab={activeSubTab} setActiveSubTab={setActiveSubTab}/>
                )}
                {renderTabContent()}
            </div>
        </>
    );
};

// Updated React Component
const SubTabs = ({activeSubTab, setActiveSubTab}) => {
    const subTabs = ['Monthly', 'Weekly', 'ALL'];
    const [sliderWidth, setSliderWidth] = useState(0);
    const [sliderOffset, setSliderOffset] = useState(0);
    const tabRefs = useRef([]);

    useEffect(() => {
        const activeIndex = subTabs.indexOf(activeSubTab);
        const activeTab = tabRefs.current[activeIndex];
        if (activeTab) {
            setSliderWidth(activeTab.offsetWidth);
            setSliderOffset(activeTab.offsetLeft);
        }
    }, [activeSubTab]);

    return (
        <div className="tab-sub-container">
            <div
                className="slider"
                style={{
                    width: `${sliderWidth}px`,
                    transform: `translateX(${sliderOffset}px)`
                }}
            />
            {subTabs.map((tab, index) => (
                <button
                    key={tab}
                    ref={el => tabRefs.current[index] = el}
                    className={`tab ${activeSubTab === tab ? 'active' : ''}`}
                    onClick={() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                        setActiveSubTab(tab);
                    }}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};

export default LeadersScreen;
