import React, { useState, useEffect } from 'react';
import styles from './../styles/PopUp.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import coinIcon from './../assets/box-coins-1.png';
import ReactGA from "react-ga4";

const PopUp = ({ isActive, onClose, preloadedImages }) => {
    const [isRendered, setIsRendered] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isActive) {
            setIsRendered(true);
            setTimeout(() => setIsVisible(true), 50);
        } else {
            setIsVisible(false);
            setTimeout(() => setIsRendered(false), 500);
        }
    }, [isActive]);

    const handleBuyNowClick = (e) => {
        ReactGA.event({
            category: "BUY_WOWCUBE",
            action: "join_presale_popup_buy_now_button",
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id
            }
        });
        e.preventDefault();
        if (window.Telegram?.WebApp?.HapticFeedback) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            window.Telegram.WebApp.openLink('https://wowcube.com/buy?coupon=TELEGRAM');
        }
    };

    if (!isRendered) return null;

    return (
        <div className={`${styles['quest-popup']} ${isVisible ? styles.active : ''}`}>
            <button className={styles['quest-popup-close']} onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
            </button>
            <div className={styles['padding-container']}>
                <div className={styles['quest-popup-content']}>
                    <h1 className={styles['quest-popup-header']}>
                        <span>WOWCUBE®</span>
                        <span className={styles.highlight}>AIRDROP</span>
                    </h1>
                    <div className={styles['quest-popup-image-container']}>
                        <img
                            className={styles['quest-popup-image']}
                            src={`${process.env.REACT_APP_SERVICE_URL}/api/images/box-coins-1.png`}
                            alt="WOWCUBE with coins"
                        />
                        <div className={styles['quest-popup-badge']}>
                            1,000,000 $WOW
                            <img
                                className={styles['money-icon']}
                                src={preloadedImages.miniCoin || "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/coin3-mini.png"}
                                alt="WOW coin icon"
                            />
                        </div>
                    </div>
                    <h4 className={styles['quest-popup-description']}>
                        Buy now to participate in exclusive WOWCUBE token AirDrop and get 1M $WOW tokens.
                    </h4>
                    <a
                        className={styles['quest-popup-cta']}
                        onClick={handleBuyNowClick}
                    >
                        BUY NOW
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PopUp;