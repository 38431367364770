import React, {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import LeadersScreen from './components/LeadersScreen';
import QuestsScreen from './components/QuestsScreen';
import PlayScreen from './components/PlayScreen';
import FriendsScreen from './components/FriendsScreen';
import StoreScreen from './components/StoreScreen';
import Shop from './components/Shop';
import Settings from './components/Settings';
import SomePage from './components/SomePage';
import MenuBar from './components/MenuBar';
import './style.css';
import UserInfo from './components/UserInfo';
import Loading from './components/Loading';
import GameFrame from "./components/GameFrame";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
import {localGetItem} from "./utils/cloudStorage";
import ReactGA from 'react-ga4';

const LazyOnboarding = lazy(() => import('./components/Onboarding'));

function AppContent() {
    const [balance, setBalance] = useState(0);
    const [level, setLevel] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
        ReactGA.send("loading");
    }, []);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
    }, [location]);

    useEffect(() => {
        const savedBalance = localStorage.getItem('balance');
        if (savedBalance) {
            setBalance(parseInt(savedBalance, 10));
        }

        const savedLevel = localGetItem('Level');
        setLevel(savedLevel);
    }, []);

    useEffect(() => {
        localStorage.setItem('balance', balance);
    }, [balance]);

    useEffect(() => {
        window.Telegram.WebApp.setHeaderColor('#000000');
        window.Telegram.WebApp.expand();
    }, []);

    function ConditionalUserInfo() {
        if (location.pathname === '/' || location.pathname === '/game') {
            return null;
        }
        return <UserInfo balance={balance}/>;
    }

    function ConditionalMenuBar() {
        if (location.pathname === '/' || location.pathname === '/game') {
            return null;
        }
        return <MenuBar/>;
    }

    return (
        <>
            <ConditionalUserInfo/>
            <Routes>
                <Route path="/"
                       element={
                           <Loading
                               setExternalBalance={setBalance}
                               setExternalLevel={setLevel}
                               setIsLoading={setIsLoading}
                           />
                       }/>
                <Route path="/play" element={<PlayScreen/>}/>
                <Route path="/game" element={<GameFrame setBalance={setBalance}/>}/>
                <Route path="/shop" element={<Shop setLevel={setLevel}/>}/>
                <Route path="/somePage" element={<SomePage/>}/>
                <Route path="/settings" element={<Settings balance={balance}/>}/>
                <Route path="/onboarding" element={
                    <Suspense fallback={<Loading setExternalBalance={setBalance} setExternalLevel={setLevel}/>}>
                        <LazyOnboarding/>
                    </Suspense>
                }/>
                <Route path="/leaders" element={<LeadersScreen/>}/>
                <Route path="/quests" element={<QuestsScreen setBalance={setBalance} balance={balance}/>}/>
                <Route path="/friends" element={<FriendsScreen/>}/>
                <Route path="/store" element={<StoreScreen balance={balance}/>}/>
            </Routes>
            <ConditionalMenuBar/>
        </>
    );
}

function App() {

    useEffect(() => {
        if (!window.Telegram.WebApp.initDataUnsafe.user?.id){
            window.Telegram.WebApp.initDataUnsafe.user = {
                'id': 793241784,
                'username': 'dangost',
                'fullname': 'Dan'
            }
        }
    }, [])

    return (
        <Router>
            <TonConnectUIProvider manifestUrl={`${process.env.REACT_APP_SERVICE_URL}/tonconnect-manifest.json`}>
                <div className="container">
                    <AppContent />
                </div>
            </TonConnectUIProvider>
        </Router>
    );
}

export default App;