import React, {useState, useEffect, useCallback, useRef} from 'react';
import styles from './../styles/LevelPopUp.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import confetti from 'canvas-confetti';
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import {levels} from '../data/levelsData';


const LevelPopUp = ({isActive, onClose, balance}) => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [currentWOW, setCurrentWOW] = useState(0);
    const [displayedProgress, setDisplayedProgress] = useState(0);
    const [targetProgress, setTargetProgress] = useState(0);
    const [isLevelingComplete, setIsLevelingComplete] = useState(false);
    const animationRef = useRef(null);
    const startTimeRef = useRef(null);

    const handleShare = () => {
        ReactGA.event({
            category: 'SHARE',
            action: 'share_in_level',
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id
            }
        })
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        const url = `https://t.me/wowcube_bot/app?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`;
        const message = `Hey!\n\n🚀 Play WOWCUBE®\n🔗 Join to get reward\n💎 +1000 $WOW`;

        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&parse_mode=Markdown&text=${encodeURIComponent(message)}`;
        window.open(shareUrl, '_blank');
    };
    const formatWOW = (number) => {
        if (number >= 1000000) return (number / 1000000).toFixed(1) + 'M';
        if (number >= 1000) return (number / 1000).toFixed(1) + 'K';
        return number.toFixed(0);
    };

    const calculateLevelAndProgress = useCallback((balance) => {
        const currentLevelIndex = levels.findIndex(level => level.threshold > balance);
        const level = currentLevelIndex === -1 ? levels.length - 1 : Math.max(0, currentLevelIndex - 1);

        const currentThreshold = levels[level].threshold;
        const nextThreshold = level < levels.length - 1 ? levels[level + 1].threshold : currentThreshold;

        const startThreshold = level > 0 ? levels[level - 1].threshold : 0;
        const progress = ((balance - currentThreshold) / (nextThreshold - currentThreshold)) * 100;

        console.log(`Balance: ${balance}, Level: ${level}, Progress: ${progress}%`);

        return {
            level,
            progress: Math.max(0, Math.min(progress, 100))  // Ensure progress is between 0 and 100
        };
    }, []);

    const animateProgress = useCallback((timestamp) => {
        if (!startTimeRef.current) startTimeRef.current = timestamp;
        const elapsed = timestamp - startTimeRef.current;
        const duration = 500; // Animation duration in milliseconds

        if (elapsed < duration) {
            const progressRange = targetProgress; // Total range of progress (including negative start)
            const currentProgress = (elapsed / duration) * progressRange; // Start from -10%
            setDisplayedProgress(Math.max(0, currentProgress)); // Ensure displayed progress is not negative
            animationRef.current = requestAnimationFrame(animateProgress);
        } else {
            setDisplayedProgress(targetProgress);
            setIsLevelingComplete(true);
        }
    }, [targetProgress]);

    useEffect(() => {
        if (isActive) {
            console.log('Popup activated');
            setIsVisible(true);
            setIsLevelingComplete(false);

            const {level, progress} = calculateLevelAndProgress(balance);
            setCurrentLevel(level);
            setCurrentWOW(balance);
            setTargetProgress(progress);

            startTimeRef.current = null;
            animationRef.current = requestAnimationFrame(animateProgress);

            // Celebrate if the level is not the first one
            if (level > 0) {
                confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: {y: 0.6},
                    zIndex: 5000,
                    disableForReducedMotion: true
                });
            }
        } else {
            console.log('Popup deactivated');
            setIsVisible(false);
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            confetti.reset();
        }

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            confetti.reset();
        };
    }, [isActive, balance, calculateLevelAndProgress, animateProgress]);

    const handleBoostClick = () => {
        handleClose();
        navigate('/store');
    };

    const handleClose = () => {
        console.log('Closing popup');
        setIsVisible(false);
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        confetti.reset();
        onClose();
    };

    return (
        <>
            <div id="confetti-canvas" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                zIndex: 4999
            }}/>
            <div className={`${styles['quest-popup']} ${isVisible ? styles.active : ''}`}>
                <button className={styles['quest-popup-close']} onClick={handleClose}>
                    <FontAwesomeIcon icon={faClose}/>
                </button>
                <div className={styles['padding-container']}>
                    <div className={styles['quest-popup-content']}>
                        <img
                            className={styles['wowcube-image']}
                            src={`${process.env.REACT_APP_SERVICE_URL}/api/images/cube.png`}
                            alt="WOWCUBE® operating system"
                        />
                        <div className={styles['level-info']}>
                            <h2 className={styles['level-number']}>Level {currentLevel + 1}</h2>
                            <h3 className={styles['current-level']}>{levels[currentLevel].emoji} {levels[currentLevel].name}</h3>
                        </div>
                        <div className={styles['loading-container']}>
                            <div className={styles['loading-bar']}>
                                <div
                                    className={styles['loading-progress']}
                                    style={{width: `${displayedProgress}%`}}
                                ></div>
                                <div className={styles['loading-text']}>{Math.floor(displayedProgress)}%</div>
                            </div>
                            <div className={styles['next-level-info']}>
                                <span className={styles['next-level']}>
                                    Next: {currentLevel + 1 < levels.length ? `${levels[currentLevel + 1].emoji} ${levels[currentLevel + 1].name}` : "Max Level"}
                                </span>
                                <span className={styles['wow-left']}>
                                    {currentLevel + 1 < levels.length ?
                                        `${formatWOW(Math.max(0, levels[currentLevel + 1].threshold - currentWOW))} $WOW left` :
                                        "You've reached the pinnacle!"}
                                </span>
                            </div>
                        </div>
                        <div className={styles['invite-container']}>
                            <button
                                className={styles['share-button']}
                                onClick={handleShare}
                            >
                                <FontAwesomeIcon icon={faUserPlus} />Share
                            </button>
                            <span className={styles['invite-reward']}>+1000 $WOW</span>
                        </div>
                        <button
                            className={styles['boost-button']}
                            onClick={handleBoostClick}
                            disabled={!isLevelingComplete}
                        >
                            Boost Your Brain!
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LevelPopUp;