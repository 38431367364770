import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faStore} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import styles from './../styles/StoreScreen.module.css';
import LevelPopUp from './../components/LevelPopUp';
import ReactGA from "react-ga4";
import {games} from '../data/gamesData';
import {calculateLevel} from '../data/levelsData';
import Loader from './Loader';  // Import the new Loader component

const handleClick = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
};

const StoreScreen = ({balance}) => {
    const navigate = useNavigate();
    const [iframeUrl, setIframeUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const animationRef = useRef(null);
    const [currentGame, setCurrentGame] = useState(null);
    const [userLevel, setUserLevel] = useState(calculateLevel(balance));
    const [isLevelPopUpActive, setIsLevelPopUpActive] = useState(false);

    useEffect(() => {
        setUserLevel(calculateLevel(balance));
    }, []);

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_store_screen",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        });
    }, []);

    const handleGameClick = (game) => {
        if (game.level && userLevel < game.level) {
            setIsLevelPopUpActive(true);
            return;
        }

        if (game.isPlayable) {
            // Для Playable игр сразу выполняем навигацию без загрузки
            navigate('/game', {
                state: {
                    gameId: game.id,
                    userId: window.Telegram.WebApp.initDataUnsafe.user?.id
                }
            });
        } else {
            setIsLoading(true);
            setLoadingProgress(0);
            setCurrentGame(game);
            hideUIElements();

            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current.frameId);
            }
            animationRef.current = {startTime: null, lastProgress: 0, isPlayable: false};
            requestAnimationFrame(animateLoading);

            setIframeUrl(game.link);
        }
    };

    const hideUIElements = () => {
        const elements = document.querySelectorAll('.user-info-header, .navigation');
        elements.forEach(element => {
            element.style.display = 'none';
        });
    };

    const showUIElements = () => {
        const elements = document.querySelectorAll('.user-info-header, .navigation');
        elements.forEach(element => {
            element.style.display = '';
        });
    };

    const setupBackButton = () => {
        const backButton = window.Telegram?.WebApp?.BackButton;
        if (backButton) {
            if (iframeUrl) {
                backButton.show();
                backButton.onClick(() => {
                    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                    setIframeUrl(null);
                    showUIElements();
                    setIsLoading(false);
                    setLoadingProgress(0);
                    window.Telegram.WebApp.setHeaderColor('#000000');
                    backButton.hide();
                    navigate('/store');
                });
            } else {
                backButton.hide();
            }
        }
    };

    useEffect(() => {
        setupBackButton();
    }, [iframeUrl]);

    const animateLoading = (timestamp) => {
        if (!animationRef.current.startTime) {
            animationRef.current.startTime = timestamp;
        }
        const elapsed = timestamp - animationRef.current.startTime;
        const duration = 3000; // Фиксированная длительность для неиграбельных игр
        const progress = Math.min(elapsed / duration, 1);

        const easeOutProgress = 1 - Math.pow(1 - progress, 3);
        const newProgress = Math.min(easeOutProgress * 90, 90);

        if (newProgress - animationRef.current.lastProgress > 0.5) {
            setLoadingProgress(newProgress);
            animationRef.current.lastProgress = newProgress;
        }

        if (progress < 1) {
            animationRef.current.frameId = requestAnimationFrame(animateLoading);
        } else {
            animationRef.current = null;
        }
    };

    const handleExternalLink = (link) => {
        setIsLoading(true);
        setLoadingProgress(0);
        setIframeUrl(link);
        hideUIElements();

        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current.frameId);
        }
        animationRef.current = {startTime: null, lastProgress: 0, isPlayable: false};
        requestAnimationFrame(animateLoading);
    };

    const handleIframeLoad = () => {
        setLoadingProgress(100);
        window.Telegram.WebApp.setHeaderColor('#fff');
        setTimeout(() => {
            setIsLoading(false);
            setLoadingProgress(0);
        }, 500);
    };

    const GameCard = ({game}) => {
        const isLocked = game.level && userLevel < game.level;

        const content = (
            <>
                <div className={styles['game-preview-container']}>
                    <img className={styles['game-preview']} src={game.preview} alt={`${game.title} preview`}/>
                    {isLocked && (
                        <div className={styles['lock-overlay']}>
                            <FontAwesomeIcon icon={faLock} className={styles['lock-icon']}/>
                        </div>
                    )}
                </div>
                <h2 className={styles['game-title']}>{game.title}</h2>
                <p className={styles['game-description']}>{game.description}</p>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <span
                        className={`${styles['game-price']} ${game.isPlayable && !isLocked ? styles.playable : ''} ${isLocked ? styles.locked : ''}`}>
                        {isLocked ? (
                            `Unlocks at Level ${game.level}`
                        ) : game.isPlayable ? (
                            game.price
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faStore}/> <span
                                style={{whiteSpace: 'pre-line'}}>{game.price}</span>
                            </>
                        )}
                    </span>
                </div>
            </>
        );

        return (
            <div
                className={`${styles['game-item']} ${game.isPlayable && !isLocked ? '' : styles['game-link']} ${isLocked ? styles['game-locked'] : ''}`}
                onClick={() => {
                    handleClick();
                    handleGameClick(game);
                }}
            >
                {content}
            </div>
        );
    };

    if (iframeUrl) {
        return (
            <div className={styles['iframe-container']}>
                {isLoading && <Loader currentGame={currentGame} loadingProgress={loadingProgress}/>}
                <iframe
                    src={iframeUrl}
                    className={styles['iframe']}
                    title="External Content"
                    onLoad={handleIframeLoad}
                    style={{display: isLoading ? 'none' : 'block'}}
                />
            </div>
        );
    }

    return (
        <div className={styles['games-container']}>
            <>
                {isLoading && <Loader currentGame={currentGame} loadingProgress={loadingProgress}/>}
                <div className={styles['game-list']}>
                    {games
                        .filter(game => !game.hidden)
                        .map((game, index) => (
                            <GameCard key={index} game={game}/>
                        ))}
                </div>
                <div className={`${styles['game-item']} ${styles['view-all']}`}>
                    <a
                        href="https://wowcube.com/store/games"
                        className={styles['view-all-link']}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClick();
                            handleExternalLink("https://wowcube.com/store/games");
                        }}
                    >
                        Explore More
                    </a>
                </div>
                <img
                    id={styles['partner-logo']}
                    src="https://www.intermedasia.com/wp-content/uploads/2024/01/Rubiks-Web-Rebrand.png"
                    alt="Rubik's Logo"
                    style={{display: "none"}}
                />
            </>
            <LevelPopUp
                isActive={isLevelPopUpActive}
                onClose={() => setIsLevelPopUpActive(false)}
                balance={balance}
            />
        </div>
    );
};

export default StoreScreen;