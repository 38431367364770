import React, {useEffect, useState} from 'react';
import '../styles/LoadingSpinner.css'; // Стиль для загрузки
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBolt, faClock, faHeart, faQuestion, faShoppingCart, faStar} from '@fortawesome/free-solid-svg-icons';
import {ArcRotateCamera, Color4, Engine, HemisphericLight, ParticleSystem, Scene, Texture, Vector3} from 'babylonjs';
import {localGetItem, localRemoveItem} from "../utils/cloudStorage";
import ReactGA from "react-ga4";

const Shop = ({setLevel}) => {
    const [token, setToken] = useState(localGetItem("Token"));
    const [loading, setLoading] = useState(false);

    const storeItems = [
        {name: "Level Up", productId: "LVLUP", price: "50 ⭐️", icon: faStar},
        {name: "$WOW points Passive Income (0.5% per day)", productId: "WOW_PASSIVE_1", price: "2000 ⭐️", icon: faBolt},
        {
            name: "WOWCUBE® Exclusive Edition WAITLIST",
            productId: "WOW_WAITLIST_EXCLUSIVE",
            price: "10000 ⭐️",
            icon: faHeart
        },
        {name: "Time Extension", productId: "TIME_EXT", price: "150 ⭐️", icon: faClock},
        {name: "Early Access to new Games", productId: "EARLY_ACCESS_GAMES", price: "300 ⭐️", icon: faQuestion},
    ];

    const createOrder = async (productId, productName) => {
        console.log(`User want to buy ${productId}`)
        window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/orders/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token // replace 'token' with the actual token if needed
                },
                body: JSON.stringify({productName: productId, stars: true})
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const paymentUrl = data.url;
            window.Telegram.WebApp.openInvoice(paymentUrl, (status) => {
                if (status === 'paid') {
                    // localRemoveItem('Level');
                    // setLevel(prevLevel => prevLevel + 1);
                    alert('Purchased successfully!');
                }
            });
        } catch (error) {
            console.error('Error creating order:', error);
            alert('Failed to create order. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_shop",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        });
        setToken(localGetItem("Token"))
        const canvas = document.getElementById('renderCanvas');
        const engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
        const scene = createScene(engine, canvas);

        engine.runRenderLoop(() => {
            scene.render();
        });

        window.addEventListener('resize', () => {
            engine.resize();
        });

        return () => {
            engine.dispose();
        };
    }, []);

    const createScene = (engine, canvas) => {
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0.02, 0.02, 0.05, 1);

        const camera = new ArcRotateCamera('camera', -Math.PI / 2, Math.PI / 2.5, 10, new Vector3(0, 0, 0), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 5;
        camera.upperRadiusLimit = 15;
        camera.wheelPrecision = 50;
        camera.pinchPrecision = 50;

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        const particleSystem = new ParticleSystem('particles', 10000, scene);
        particleSystem.particleTexture = new Texture('https://www.babylonjs-playground.com/textures/flare.png', scene);
        particleSystem.emitter = new Vector3(0, 0, 0);
        particleSystem.minEmitBox = new Vector3(-10, -10, -10);
        particleSystem.maxEmitBox = new Vector3(10, 10, 10);
        particleSystem.color1 = new Color4(0.7, 0.4, 1, 1);
        particleSystem.color2 = new Color4(0.5, 0.3, 0.8, 1);
        particleSystem.colorDead = new Color4(0.2, 0.2, 0.5, 0);
        particleSystem.minSize = 0.05;
        particleSystem.maxSize = 0.3;
        particleSystem.minLifeTime = 2;
        particleSystem.maxLifeTime = 8;
        particleSystem.emitRate = 1000;
        particleSystem.blendMode = ParticleSystem.BLENDMODE_ADD;
        particleSystem.gravity = new Vector3(0, -0.05, 0);
        particleSystem.direction1 = new Vector3(-1, -1, -1);
        particleSystem.direction2 = new Vector3(1, 1, 1);
        particleSystem.minAngularSpeed = 0;
        particleSystem.maxAngularSpeed = Math.PI;
        particleSystem.minEmitPower = 0.5;
        particleSystem.maxEmitPower = 2;
        particleSystem.updateSpeed = 0.01;
        particleSystem.start();

        return scene;
    };

    const handleBuyWowCube = () => {
        ReactGA.event({
            category: "BUY_WOWCUBE",
            action: "shop_buy_wowcube_button",
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id
            }
        });
        window.open('https://wowcube.com/buy?coupon=TELEGRAM', '_blank');
    };

    return (
        <>
            <canvas id='renderCanvas' touch-action="none"></canvas>
            <div className="screen">
                <div className="store-title">WOWCUBE® Shop</div>
                <div className="store">
                    {storeItems.map((item, index) => (
                        <div className="store-item" key={index} onClick={() => {
                            createOrder(item.productId, item.name )
                        }}>
                            <div className="store-info">
                                <FontAwesomeIcon icon={item.icon} className="store-icon"/>
                                <span className="store-name">{item.name}</span>
                            </div>
                            <span className="store-price">{item.price}</span>
                        </div>
                    ))}
                    <button className="connect-wallet-button" onClick={handleBuyWowCube} style={{padding: "20px"}}>
                        <FontAwesomeIcon icon={faShoppingCart}/> BUY WOWCUBE
                    </button>
                </div>

            </div>

            {loading && (
                <div className="loading-overlay-local">
                    <div className="spinner-local"></div>
                </div>
            )}
        </>
    );
};

export default Shop;