import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTasks, faUserFriends, faPlay, faStore, faTrophy, faCoins} from '@fortawesome/free-solid-svg-icons';

const MenuBar = () => {

    return (
        <nav className="navigation">
            <Link to="/quests" className="nav-button" onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            }}>
                <FontAwesomeIcon icon={faCoins} className="nav-icon"/>
                <span>Earn</span>
            </Link>
            <Link to="/friends" className="nav-button" onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            }}>
                <FontAwesomeIcon icon={faUserFriends} className="nav-icon"/>
                <span>Friends</span>
            </Link>
            <Link to="/game" className="nav-button play-button" onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            }}>
                <FontAwesomeIcon icon={faPlay} className="nav-icon"/>
                <span>Play</span>
            </Link>
            <Link to="/store" className="nav-button" onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            }}>
                <FontAwesomeIcon icon={faStore} className="nav-icon"/>
                <span>Games</span>
            </Link>
            <Link to="/leaders" className="nav-button" onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            }}>
                <FontAwesomeIcon icon={faTrophy} className="nav-icon"/>
                <span>Leaders</span>
            </Link>
        </nav>
    );
};

export default MenuBar;