import React, {useState, useEffect} from 'react';
import {
    Engine,
    Scene,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    ParticleSystem,
    Texture,
    Color4
} from 'babylonjs';
import PopUp from "./PopUp";
import ReactGA from "react-ga4";

const PlayScreen = () => {

    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [preloadedImages, setPreloadedImages] = useState({});

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_play_screen",
            label: window.Telegram.WebApp.initData.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initData.user?.id
            }
        })
    }, [])

    const imagesToPreload = [
        {key: 'coinIcon', src: `${process.env.REACT_APP_SERVICE_URL}/api/images/box-coins-1.png`},
        {
            key: 'miniCoin',
            src: 'https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/coin3-mini.png'
        }
    ];

    useEffect(() => {
        const canvas = document.getElementById('renderCanvas');
        const engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
        const scene = createScene(engine, canvas);

        engine.runRenderLoop(() => {
            scene.render();
        });

        window.addEventListener('resize', () => {
            engine.resize();
        });

         // Preload images
        preloadImages();

        return () => {
            engine.dispose();
        };
    }, []);

    const preloadImages = () => {
        const loadImage = (imageObj) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve({ key: imageObj.key, img });
                img.onerror = () => reject(new Error(`Failed to load image: ${imageObj.src}`));
                img.src = imageObj.src;
            });
        };

        Promise.all(imagesToPreload.map(loadImage))
            .then(loadedImgs => {
                const imgMap = loadedImgs.reduce((acc, { key, img }) => {
                    acc[key] = img.src;
                    return acc;
                }, {});
                setPreloadedImages(imgMap);
            })
            .catch(error => {
                console.error('Error preloading images:', error);
            });
    };

    useEffect(() => {
        if (isModelLoaded) {
            setTimeout(() => {
                setIsImageVisible(false);
            }, 1);
        }
    }, [isModelLoaded]);


    const handleModelLoad = () => {
        setIsModelLoaded(true);
    };

    const createScene = (engine, canvas) => {
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0.02, 0.02, 0.05, 1);

        const camera = new ArcRotateCamera('camera', -Math.PI / 2, Math.PI / 2.5, 10, new Vector3(0, 0, 0), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 5;
        camera.upperRadiusLimit = 15;
        camera.wheelPrecision = 50;
        camera.pinchPrecision = 50;

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        const particleSystem = new ParticleSystem('particles', 10000, scene);
        particleSystem.particleTexture = new Texture('https://www.babylonjs-playground.com/textures/flare.png', scene);
        particleSystem.emitter = new Vector3(0, 0, 0);
        particleSystem.minEmitBox = new Vector3(-10, -10, -10);
        particleSystem.maxEmitBox = new Vector3(10, 10, 10);
        particleSystem.color1 = new Color4(0.7, 0.4, 1, 1);
        particleSystem.color2 = new Color4(0.5, 0.3, 0.8, 1);
        particleSystem.colorDead = new Color4(0.2, 0.2, 0.5, 0);
        particleSystem.minSize = 0.05;
        particleSystem.maxSize = 0.3;
        particleSystem.minLifeTime = 2;
        particleSystem.maxLifeTime = 8;
        particleSystem.emitRate = 1000;
        particleSystem.blendMode = ParticleSystem.BLENDMODE_ADD;
        particleSystem.gravity = new Vector3(0, -0.05, 0);
        particleSystem.direction1 = new Vector3(-1, -1, -1);
        particleSystem.direction2 = new Vector3(1, 1, 1);
        particleSystem.minAngularSpeed = 0;
        particleSystem.maxAngularSpeed = Math.PI;
        particleSystem.minEmitPower = 0.5;
        particleSystem.maxEmitPower = 2;
        particleSystem.updateSpeed = 0.01;
        particleSystem.start();

        let time = 0;
        engine.runRenderLoop(() => {
            time += 0.016;

            const r = 0.54 + Math.sin(time * 1.1) * 0.1;
            const g = 0.17 + Math.sin(time * 1.3) * 0.05;
            const b = 0.89 + Math.sin(time * 1.7) * 0.1;

            particleSystem.color1 = new Color4(r + 0.2, g + 0.2, b + 0.1, 1);
            particleSystem.color2 = new Color4(r - 0.1, g + 0.1, b - 0.1, 1);

            scene.render();
        });

        return scene;
    };

    const handlePreSaleClick = (e) => {
        ReactGA.event({
            category: "BUY_WOWCUBE",
            action: "join_presale_mainpage",
            label: window.Telegram.WebApp.initData.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initData.user?.id
            }
        });
        e.preventDefault();
        setIsPopUpOpen(true);
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    };

    return (
        <div className='wrapper'>
            <canvas id='renderCanvas' touch-action="none"></canvas>
            <div id="playScreen" className="screen">
                <h2>The Next Dimension of Gaming™</h2>
                <div id="wowcube-image-container" style={{position: 'relative', width: '100%', height: '388px'}}>
                    <img
                        id="wowcube-image"
                        src={`${process.env.REACT_APP_SERVICE_URL}/api/images/logo.webp`}
                        alt="WOWCUBE® operating system"
                        style={{
                            width: '100%',
                            height: '288px',
                            objectFit: 'cover',
                            position: 'absolute',
                            opacity: isImageVisible ? 1 : 0,
                            transition: 'opacity 2s ease',
                            zIndex: isImageVisible ? 2 : 1
                        }}
                    />
                    <iframe
                        id="modelViewer"
                        src="https://www.vectary.com/viewer/v1/?model=622185a8-4b77-4a77-b91d-90df2e8b7259&env=studio2&mouseFollow=0.5&doubleClickToFocus=0&showPreloader=1&noAR=1&zoom=0.5&showPlaceholder=0&enableApi=1"
                        frameBorder="0"
                        width="100%"
                        height="488"
                        onLoad={handleModelLoad}
                        title="WOWCUBE® 3D Model"
                        style={{
                            position: 'relative',
                            zIndex: 1,
                            marginTop: '-100px'
                        }}
                    ></iframe>
                </div>
            </div>
            <a
                href="#"
                className="presale-button"
                onClick={handlePreSaleClick}
                style={{
                    position: 'absolute',
                    bottom: '200px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 3000,
                    padding: '10px 20px',
                }}
            >
                JOIN PRE-SALE
            </a>
            <PopUp
                isActive={isPopUpOpen}
                onClose={() => {
                    setIsPopUpOpen(false);
                    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                }}
                preloadedImages={preloadedImages}
            />
        </div>
    );
};


export default PlayScreen;
