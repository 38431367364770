import React from 'react';
import styles from './../styles/Loading.module.css';

const Loader = ({ currentGame, loadingProgress }) => {
  return (
    <div className={styles.playScreen}>
      <h3 className={styles.title}>WOWCUBE®</h3>
      {currentGame && (
        <>
          <img
            className={styles.image}
            src={currentGame.preview}
            alt="Game preview"
          />
          <h4 className={styles.gameTitle}>
            {currentGame.title}
          </h4>
        </>
      )}
      <div className={styles.loadingBar}>
        <div
          className={styles.loadingProgress}
          style={{
            width: `${loadingProgress}%`,
            transition: 'width 0.3s ease-out'
          }}
        ></div>
      </div>
      <div className={styles.loadingText}>Loading... {Math.round(loadingProgress)}%</div>
      <img className={styles.partnerLogo}
           src="https://www.intermedasia.com/wp-content/uploads/2024/01/Rubiks-Web-Rebrand.png"
           style={{display: "none"}}
           alt="Rubik's Logo"/>
    </div>
  );
};

export default Loader;
