import React, {useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar, faCoins, faCog} from '@fortawesome/free-solid-svg-icons';
import LevelPopUp from "./LevelPopUp";
import {levels} from '../data/levelsData';
import PromoHandlerUtility from "../utils/PromoHandlerUtility";

const UserInfo = ({balance}) => {
    const navigate = useNavigate();
    const [isPopupActive, setIsPopupActive] = useState(false);

    const calculateLevel = useMemo(() => {
        const currentLevelIndex = levels.findIndex(level => level.threshold > balance);
        let level = currentLevelIndex === -1 ? levels.length : Math.max(1, currentLevelIndex)

        // if (level >= 5) {
        //     PromoHandlerUtility.sendPromoCallback(window.Telegram.WebApp.initDataUnsafe.user?.id);
        // }

        return level;
    }, [balance]);

    const formatBalance = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const handleClick = () => {
        if (window.Telegram?.WebApp?.HapticFeedback) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }
    };

    const handleLevelClick = () => {
        handleClick();
        setIsPopupActive(true);
    };

    const handleBalanceClick = () => {
        handleClick();
        navigate('/shop');
    };

    const handleSettingsClick = (e) => {
        e.stopPropagation();
        handleClick();
        navigate('/settings');
    };

    const handleClosePopup = () => {
        setIsPopupActive(false);
    };

    return (
        <div>
            {!isPopupActive ? (
                <div className="user-info-header">
                    <div className="user-level" onClick={handleLevelClick}>
                        <FontAwesomeIcon icon={faStar}/>
                        <span>Level {calculateLevel}</span>
                    </div>
                    <div className="user-balance" onClick={handleBalanceClick}>
                        <FontAwesomeIcon icon={faCoins}/>
                        <span>{formatBalance(balance)} $WOW</span>
                    </div>
                    <FontAwesomeIcon
                        icon={faCog}
                        className="settings-icon"
                        id="settingsIcon"
                        onClick={handleSettingsClick}
                    />
                </div>
            ) : (
                <LevelPopUp
                    isActive={isPopupActive}
                    onClose={handleClosePopup}
                    balance={balance}
                />
            )}
        </div>
    );
};

export default UserInfo;