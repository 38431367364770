import PopUp from './PopUp';

const SomePage = () => {
  return (
    <div>
      <h1>Добро пожаловать на страницу</h1>
      <p>Здесь какой-то контент...</p>
      <PopUp />
    </div>
  );
};

export default SomePage;