import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import {getGameById, getGameSequence} from '../data/gamesData';
import {localGetItem, localSetItem} from "../utils/cloudStorage";
import PromoHandlerUtility from '../utils/PromoHandlerUtility';
import Loader from './Loader';

const GameFrame = ({setBalance}) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localGetItem("Token"));
    const location = useLocation();
    const userId = window.Telegram.WebApp.initDataUnsafe.user?.id;
    const iframeRef = useRef(null);
    const loadingIntervalRef = useRef(null);

    const initialGameId = location.state?.gameId || "1";

    const [currentGameId, setCurrentGameId] = useState(initialGameId);
    const [gameSequence, setGameSequence] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [iframeKey, setIframeKey] = useState(0);
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);

    const MIN_LOADING_TIME = 3000; // Минимальное время загрузки в миллисекундах
    const LOADING_STEP = 5; // Шаг увеличения прогресса
    const LOADING_INTERVAL = 100; // Интервал обновления прогресса в миллисекундах

    const loadGame = useCallback((gameId) => {
        setIsLoading(true);
        setLoadingProgress(0);
        setIsIframeLoaded(false);
        setCurrentGameId(gameId);
        const game = getGameById(gameId);
        if (!game) {
            console.error(`Game with id ${gameId} not found`);
            setIsLoading(false);
            setLoadingProgress(0);
            return;
        }
        const showTutor = localGetItem("tutor") === null;

        if (showTutor) {
            localSetItem('tutor', false, 3600 * 24 * 365);
        }

        ReactGA.event({
            category: "PAGES",
            action: "open_game_frame",
            label: userId,
            customParameters: {
                userId: userId,
                gameId: gameId
            }
        });

        if (userId) {
            PromoHandlerUtility.sendPromoCallback(userId);
        }

        setIframeKey(prevKey => prevKey + 1);

        const startTime = Date.now();
        if (loadingIntervalRef.current) {
            clearInterval(loadingIntervalRef.current);
        }

        loadingIntervalRef.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min(Math.floor((elapsedTime / MIN_LOADING_TIME) * 100), 99);
            setLoadingProgress(progress);

            if (elapsedTime >= MIN_LOADING_TIME && isIframeLoaded) {
                clearInterval(loadingIntervalRef.current);
                setLoadingProgress(100);
                setTimeout(() => {
                    setIsLoading(false);
                    setLoadingProgress(0);
                }, 500);
            }
        }, LOADING_INTERVAL);
    }, [userId]);

    useEffect(() => {
        const sequence = getGameSequence();
        setGameSequence(sequence);
        loadGame(initialGameId);

        return () => {
            if (loadingIntervalRef.current) {
                clearInterval(loadingIntervalRef.current);
            }
        };
    }, [initialGameId, loadGame]);

    const handleIframeLoad = () => {
        setIsIframeLoaded(true);
        if (loadingProgress >= 99) {
            setLoadingProgress(100);
            setTimeout(() => {
                setIsLoading(false);
                setLoadingProgress(0);
            }, 500);
        }
    };

    const handleNextGame = useCallback(() => {
        if (gameSequence.length === 0) {
            console.error('Game sequence is empty');
            return;
        }

        const currentIndex = gameSequence.findIndex(game => game.id === currentGameId);
        if (currentIndex === -1) {
            console.error(`Current game with id ${currentGameId} not found in sequence`);
            loadGame(gameSequence[0].id);
            return;
        }

        const nextIndex = (currentIndex + 1) % gameSequence.length;
        const nextGameId = gameSequence[nextIndex].id;
        loadGame(nextGameId);
    }, [currentGameId, gameSequence, loadGame]);

    const handleMessage = useCallback((event) => {
        switch (event.data.type) {
            case 'nextGame':
                handleNextGame();
                break;
            case 'getTelegramWebApp':
                sendTelegramWebApp();
                break;
            case 'disableVerticalSwipes':
                disableVerticalSwipes();
                break;
            case 'setHeaderColor':
                setHeaderColor();
                break;
            case 'hapticFeedback':
                triggerHapticFeedback();
                break;
            case 'gameScore':
                setBalance((prevBalance) => prevBalance + event.data.score);
                break;
            case 'shareGame':
                handleShare(event.data.score, currentGame.title);
                break;
            default:
                console.log('Unknown message type:', event.data.type);
        }
    }, [handleNextGame, setBalance]);

    const backButtonHandler = useRef(() => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({type: 'saveGameState'}, '*');
        }
        setTimeout(() => {
            window.Telegram.WebApp.BackButton.hide();
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

            navigate('/store');
        }, 100);
    });

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        if (window.Telegram && window.Telegram.WebApp) {
            const backButton = window.Telegram.WebApp.BackButton;
            backButton.show();
            backButton.onClick(backButtonHandler.current);
        }

        return () => {
            window.removeEventListener('message', handleMessage);
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.BackButton.offClick(backButtonHandler.current);
            }
        };
    }, [handleMessage, navigate]);

    const sendTelegramWebApp = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            const telegramWebAppData = {
                type: 'telegramWebAppData',
                webApp: window.Telegram.WebApp
            };
            iframeRef.current?.contentWindow.postMessage(telegramWebAppData, '*');
        }
    };

    const handleShare = (score, title) => {
        ReactGA.event({
            category: 'SHARE',
            action: 'share_game_score',
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id,
                score: score,
                gameTitle: title
            }
        })
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        const url = `https://t.me/wowcube_bot/app?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`;
        const message = `Hey!\n\n🎮 I scored ${score} $WOW points in ${title || 'WOWCUBE®'}!\n🔗 Join to beat my record\n💎And get +1000 $WOW by joining.`;

        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&parse_mode=Markdown&text=${encodeURIComponent(message)}`;
        window.open(shareUrl, '_blank');
    };

    const disableVerticalSwipes = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.disableVerticalSwipes();
        }
    };

    const setHeaderColor = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.setHeaderColor('#000000');
        }
    };

    const triggerHapticFeedback = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }
    };

    const currentGame = getGameById(currentGameId);

    return (
        <>
            {isLoading && (
                <Loader
                    currentGame={currentGame}
                    loadingProgress={loadingProgress}
                />
            )}
            {currentGame && (
                <iframe
                    ref={iframeRef}
                    key={iframeKey}
                    src={`${currentGame.link}?user_id=${userId}&game_id=${currentGameId}&tutor=${localGetItem("tutor") === null}&token=${token}`}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        margin: 0,
                        padding: 0,
                        overflow: 'hidden',
                        zIndex: 999999
                    }}
                    title={currentGame.title}
                    onLoad={handleIframeLoad}
                />
            )}
        </>
    );
};

export default GameFrame;