// src/data/gamesData.js

export const levels = [
    {emoji: "👶", name: "Novice Explorer", threshold: 100},
    {emoji: "🐣", name: "Curious Beginner", threshold: 250},
    {emoji: "🔍", name: "Inquisitive Mind", threshold: 500},
    {emoji: "🧩", name: "Puzzle Enthusiast", threshold: 1000},
    {emoji: "🧒", name: "Junior Thinker", threshold: 2000},
    {emoji: "🎈", name: "Idea Starter", threshold: 3500},
    {emoji: "🌱", name: "Knowledge Sprout", threshold: 5000},
    {emoji: "✏️", name: "Apprentice Scholar", threshold: 7500},
    {emoji: "🔎", name: "Riddle Solver", threshold: 10000},
    {emoji: "📝", name: "Dedicated Learner", threshold: 15000},
    {emoji: "🎒", name: "Adept Student", threshold: 20000},
    {emoji: "🧮", name: "Math Whiz", threshold: 30000},
    {emoji: "🧑‍🏫", name: "Analyst", threshold: 40000},
    {emoji: "🎓", name: "Strategist", threshold: 55000},
    {emoji: "🚀", name: "Critical Thinker", threshold: 75000},
    {emoji: "📖", name: "Researcher", threshold: 100000},
    {emoji: "🧠", name: "Intellectual", threshold: 150000},
    {emoji: "🧑‍🔬", name: "Innovator", threshold: 200000},
    {emoji: "💡", name: "Inventor", threshold: 300000},
    {emoji: "📝", name: "Philosopher", threshold: 400000},
    {emoji: "🧑‍🏫", name: "Sage", threshold: 550000},
    {emoji: "🧙‍♂️", name: "Wizard of Wisdom", threshold: 750000},
    {emoji: "👨‍🏫", name: "Mastermind", threshold: 1000000},
    {emoji: "🌐", name: "Global Thinker", threshold: 1500000},
    {emoji: "🌟", name: "Luminary", threshold: 2000000},
    {emoji: "🔮", name: "Visionary", threshold: 3000000},
    {emoji: "🧠", name: "Polymath", threshold: 4000000},
    {emoji: "🌀", name: "Oracle", threshold: 5500000},
    {emoji: "☄️", name: "Enlightened One", threshold: 7500000},
    {emoji: "🧞‍♂️", name: "Supreme Intellect", threshold: 10000000}
];

export const calculateLevel = (balance) => {
    const currentLevelIndex = levels.findIndex(level => level.threshold > balance);
    return currentLevelIndex === -1 ? levels.length : Math.max(1, currentLevelIndex);
};