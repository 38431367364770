// cloudStorage.js

// Получение элемента по ключу с проверкой истечения срока
export async function cloudGetItem(key, callback = null) {
    return new Promise((resolve, reject) => {
        window.Telegram.WebApp.CloudStorage.getItem(key, (error, itemString) => {
            if (error) {
                reject(error);
                return;
            }
            if (!itemString) {
                resolve(null);
                return;
            }
            const item = JSON.parse(itemString);
            const now = Date.now();
            if (now > item.expiry) {
                if (callback) {
                    callback(item.value)
                }
                cloudRemoveItem(key).then(() => resolve(null)).catch(reject);
            } else {
                resolve(item.value);
            }
        });
    });
}

// Установка элемента по ключу с временем истечения в секундах
export async function cloudSetItem(key, value, expireInSeconds = null) {
    return new Promise((resolve, reject) => {
        const now = Date.now();
        const item = {
            value,
            expiry: expireInSeconds ? now + expireInSeconds * 1000 : null
        };
        const itemString = JSON.stringify(item);
        window.Telegram.WebApp.CloudStorage.setItem(key, itemString, (error) => {
            if (error) {
                reject(error);
            } else {
                resolve();
            }
        });
    });
}

// Удаление элемента по ключу
export async function cloudRemoveItem(key) {
    return new Promise((resolve, reject) => {
        window.Telegram.WebApp.CloudStorage.removeItem(key, (error) => {
            if (error) {
                reject(error);
            } else {
                resolve();
            }
        });
    });
}

// Получение всех ключей хранилища
export async function cloudGetKeys() {
    return new Promise((resolve, reject) => {
        window.Telegram.WebApp.CloudStorage.getKeys((error, keys) => {
            if (error) {
                reject(error);
            } else {
                resolve(keys);
            }
        });
    });
}

// Пример использования в вашем коде:
// import { cloudGetItem, cloudSetItem, cloudRemoveItem, cloudGetKeys } from './cloudStorage';

// async function manageData() {
//     try {
//         const data = await cloudGetItem('myData');
//         console.log('Data retrieved:', data);

//         await cloudSetItem('newData', { myKey: 'myValue' }, 60); // Expires in 60 seconds
//         console.log('Data set with expiration.');

//         const keys = await cloudGetKeys();
//         console.log('Keys in storage:', keys);

//         await cloudRemoveItem('oldData');
//         console.log('Data removed successfully.');
//     } catch (error) {
//         console.error('Error managing data:', error);
//     }
// }

// localStorageManager.js

// Получение элемента по ключу с проверкой истечения срока
export function localGetItem(key, callback = null) {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
        return null;
    }
    const item = JSON.parse(itemString);
    const now = Date.now();
    if (item.expiry && now > item.expiry) {
        if (callback) {
            callback(item.value);
        }
        localRemoveItem(key);
        return null;
    }
    return item.value;
}

// Установка элемента по ключу с временем истечения в секундах
export function localSetItem(key, value, expireInSeconds = null) {
    const now = Date.now();
    const item = {
        value,
        expiry: expireInSeconds ? now + expireInSeconds * 1000 : null
    };
    const itemString = JSON.stringify(item);
    localStorage.setItem(key, itemString);
}

// Удаление элемента по ключу
export function localRemoveItem(key) {
    localStorage.removeItem(key);
}

// Получение всех ключей хранилища
export function localGetKeys() {
    return Object.keys(localStorage);
}

export function localFlush() {
    localStorage.clear();
}

// Пример использования в вашем коде:
// import { localGetItem, localSetItem, localRemoveItem, localGetKeys } from './localStorageManager';

function manageData() {
    const data = localGetItem('myData');
    console.log('Data retrieved:', data);

    localSetItem('newData', {myKey: 'myValue'}, 60); // Expires in 60 seconds
    console.log('Data set with expiration.');

    const keys = localGetKeys();
    console.log('Keys in storage:', keys);

    localRemoveItem('oldData');
    console.log('Data removed successfully.');
}



