import React, {useEffect, useState} from 'react';
import useSWR from 'swr';
import {ArcRotateCamera, Color4, Engine, HemisphericLight, ParticleSystem, Scene, Texture, Vector3} from 'babylonjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faCamera, faCrown, faShareAlt, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {localGetItem} from "../utils/cloudStorage";
import ReactGA from "react-ga4";
import { calculateLevel } from '../data/levelsData';

// Custom Card component
const Card = ({className, children}) => (
    <div className={`card ${className}`}>{children}</div>
);

const CardHeader = ({children}) => (
    <div className="card-header">{children}</div>
);

const CardContent = ({children}) => (
    <div className="card-content">{children}</div>
);

// Custom Button component
const Button = ({className, onClick, children}) => (
    <button className={`button ${className}`} onClick={onClick}>
        {children}
    </button>
);

// Функция для запроса данных
const fetcher = (url, token) => {
    return fetch(url, {
        headers: {
            "Authorization": token
        }
    }).then((res) => res.json());
};

const FriendsScreen = () => {
    const [token, setToken] = useState(localGetItem("Token"));
    const [activeTab, setActiveTab] = useState('bonus');
    const invitedFriendsGoal = 10; // Цель для приглашённых друзей

    // Запрос для получения списка друзей
    const {data: friendsData, error: friendsError} = useSWR(
        token ? `${process.env.REACT_APP_SERVICE_URL}/api/connections` : null,
        (url) => fetcher(url, token)
    );

    // Сортируем друзей по балансу
    const sortedFriendsData = friendsData ? [...friendsData].sort((a, b) => b.balance - a.balance) : null;

    // Запрос для получения количества приглашённых друзей через SWR
    const {data: invitedFriendsData, error: invitedFriendsError} = useSWR(
        token ? `${process.env.REACT_APP_SERVICE_URL}/api/total-passive` : null,
        (url) => fetcher(url, token)
    );

    const invitedFriends = invitedFriendsData?.first_referrals || 0; // Количество приглашённых друзей

    useEffect(() => {
        ReactGA.event({
            category: "PAGES",
            action: "open_friends_screen",
            label: window.Telegram.WebApp.initDataUnsafe.user?.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user?.id
            }
        })
    }, [])

    // Логирование данных или ошибок
    useEffect(() => {
        if (invitedFriendsError) {
            console.error('Ошибка при запросе приглашённых друзей:', invitedFriendsError);
        } else if (invitedFriendsData) {
            console.log('Данные о приглашённых друзьях:', invitedFriendsData);
        }

        if (friendsError) {
            console.error('Ошибка при запросе данных друзей:', friendsError);
        } else if (friendsData) {
            console.log('Данные о друзьях:', friendsData);
        }
    }, [invitedFriendsData, invitedFriendsError, friendsData, friendsError]);

    // Babylon.js рендер сцены
    useEffect(() => {
        const canvas = document.getElementById('renderCanvas');
        const engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
        const scene = createScene(engine, canvas);

        engine.runRenderLoop(() => {
            scene.render();
        });

        window.addEventListener('resize', () => {
            engine.resize();
        });

        return () => {
            engine.dispose();
        };
    }, []);

    const createScene = (engine, canvas) => {
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0.02, 0.02, 0.05, 1);

        const camera = new ArcRotateCamera('camera', -Math.PI / 2, Math.PI / 2.5, 10, new Vector3(0, 0, 0), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 5;
        camera.upperRadiusLimit = 15;
        camera.wheelPrecision = 50;
        camera.pinchPrecision = 50;

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        const particleSystem = new ParticleSystem('particles', 10000, scene);
        particleSystem.particleTexture = new Texture('https://www.babylonjs-playground.com/textures/flare.png', scene);
        particleSystem.emitter = new Vector3(0, 0, 0);
        particleSystem.minEmitBox = new Vector3(-10, -10, -10);
        particleSystem.maxEmitBox = new Vector3(10, 10, 10);
        particleSystem.color1 = new Color4(0.7, 0.4, 1, 1);
        particleSystem.color2 = new Color4(0.5, 0.3, 0.8, 1);
        particleSystem.colorDead = new Color4(0.2, 0.2, 0.5, 0);
        particleSystem.minSize = 0.05;
        particleSystem.maxSize = 0.3;
        particleSystem.minLifeTime = 2;
        particleSystem.maxLifeTime = 8;
        particleSystem.emitRate = 1000;
        particleSystem.blendMode = ParticleSystem.BLENDMODE_ADD;
        particleSystem.gravity = new Vector3(0, -0.05, 0);
        particleSystem.direction1 = new Vector3(-1, -1, -1);
        particleSystem.direction2 = new Vector3(1, 1, 1);
        particleSystem.minAngularSpeed = 0;
        particleSystem.maxAngularSpeed = Math.PI;
        particleSystem.minEmitPower = 0.5;
        particleSystem.maxEmitPower = 2;
        particleSystem.updateSpeed = 0.01;
        particleSystem.start();

        return scene;
    };

    const handleShare = () => {
        ReactGA.event({
            category: 'SHARE',
            action: 'share_default',
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id
            }
        })
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        const url = `https://t.me/wowcube_bot/app?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`;
        const message = `Hey!\n\n🚀 Play WOWCUBE®\n🔗 Join to get reward\n💎 +1000 $WOW`;

        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&parse_mode=Markdown&text=${encodeURIComponent(message)}`;
        window.open(shareUrl, '_blank');
    };

    const handleShareDad = () => {
        ReactGA.event({
            category: 'SHARE',
            action: 'share_with_parents',
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id
            }
        })
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        const url = `https://t.me/wowcube_bot/app?startapp=parent_${window.Telegram.WebApp.initDataUnsafe.user.id}`;
        const message = `\nHey, This is amazing!\n\nI want this WOWCUBE as a present.\n\nPLEAAAASE 🙏`;

        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&parse_mode=Markdown&text=${encodeURIComponent(message)}`;
        window.open(shareUrl, '_blank');
    };

    const handlePostStories = (mediaUrl, widgetLinkUrl = '', widgetLinkName = '', text = '') => {
        ReactGA.event({
            category: 'SHARE',
            action: 'post_to_stories',
            label: window.Telegram.WebApp.initDataUnsafe.user.id,
            customParameters: {
                userId: window.Telegram.WebApp.initDataUnsafe.user.id
            }
        })

        const params = {};

        if (widgetLinkUrl) {
            const widgetLink = {
                url: widgetLinkUrl
            };
            if (widgetLinkName) {
                widgetLink.name = widgetLinkName;
            }
            params.widget_link = widgetLink;
        }
        if (text) {
            params.text = text;
        }
        window.Telegram.WebApp.shareToStory(mediaUrl, params);
    };

    return (
        <div className='wrapper'>
            <canvas id='renderCanvas' touch-action="none"></canvas>
            <div className="screen active">
                <div className="tab-container">
                    <Button
                        className={`tab ${activeTab === 'bonus' ? 'active' : ''}`}
                        onClick={() => setActiveTab('bonus')}
                    >
                        Bonus
                    </Button>
                    <Button
                        className={`tab ${activeTab === 'friends' ? 'active' : ''}`}
                        onClick={() => setActiveTab('friends')}
                    >
                        Friends
                    </Button>
                </div>

                {/* Контент для вкладки "Бонусы" */}
                {activeTab === 'bonus' && (
                    <div className="friends-content active">
                        <div className="friends-progress" onClick={handleShare}>
                            <div className="progress-bar">
                                <div
                                    className="progress-fill"
                                    style={{width: `${(invitedFriends / invitedFriendsGoal) * 100}%`}}
                                />
                                <div className="progress-info">
                                    {invitedFriends} / {invitedFriendsGoal} friends invited
                                </div>
                            </div>
                        </div>

                        <div className="friends-rewards" onClick={handleShare}>
                            <Card className="reward-card" onClick={handleShare}>
                                <CardHeader>
                                    <FontAwesomeIcon icon={faUserPlus}/>
                                    <p>Invite a friend <br/> and get</p>
                                </CardHeader>
                                <CardContent>
                                    <span className="reward-amount">1000 $WOW</span>
                                </CardContent>
                            </Card>
                            <Card className="reward-card premium" onClick={handleShare}>
                                <CardHeader>
                                    <FontAwesomeIcon icon={faCrown}/>
                                    <p>Invite a TG Premium friend</p>
                                </CardHeader>
                                <CardContent>
                                    <span className="reward-amount">5000 $WOW</span>
                                </CardContent>
                            </Card>
                        </div>

                        <div className="friends-actions">
                            <Button className="friends-action-btn share-dad-btn" onClick={handleShareDad}>
                                <FontAwesomeIcon icon={faBell}/> Share with PARENTS
                            </Button>
                            <Button className="friends-action-btn" onClick={() => handlePostStories(
                                `https://github.com/dangost/static-test/raw/refs/heads/master/story.mp4`,
                                `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/app?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`,
                                'Learn more',
                                `Join 🚀 WOWCUBE® - https://t.me/${process.env.REACT_APP_BOT_USERNAME}/app?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`
                            )}>
                                <FontAwesomeIcon icon={faCamera}/> Post to Stories
                            </Button>
                            <Button className="friends-action-btn" onClick={handleShare}>
                                <FontAwesomeIcon icon={faShareAlt}/> Share
                            </Button>
                        </div>
                    </div>
                )}

                {activeTab === 'friends' && (
                    <div className="friends-list-content">
                        {sortedFriendsData && sortedFriendsData.length === 0 &&
                            <div className="no-friends-message">You have no friends yet</div>}

                        {sortedFriendsData && sortedFriendsData.length > 0 && (
                            <div className="friends">
                                {sortedFriendsData.map((friend, index) => (
                                    <div className="friend-item" key={index}>
                                        <div className="friend-info">
                                            <div className="friend-avatar"
                                                 style={{
                                                     backgroundImage: `url(${friend.avatar_url || 'https://via.placeholder.com/50'})`,
                                                 }}></div>
                                            <span
                                                className="friend-name">{friend.username ? `@${friend.username}` : 'No Username'}</span>
                                        </div>
                                        <span className="friend-level">Level {calculateLevel(friend.balance)}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FriendsScreen;
